init();

function init(): void {
    document.addEventListener('DOMContentLoaded', () => {
        const colorScheme = getState('colorScheme');
        if (typeof colorScheme === 'string') {
            updateTheme(colorScheme);
        }
    });

    window.addEventListener('message', ({ data }: { data: unknown }) => {
        if (typeof data === 'object' && null !== data && 'theme' in data && typeof data.theme === 'string') {
            updateTheme(data.theme);
        }
    });
}

function getState(state: string): unknown {
    const item = localStorage.getItem(`qbil.${state}`);
    if (null !== item) {
        return JSON.parse(item);
    }
    return null;
}

function updateUISetting(setting: string, value: string): void {
    const rootElement = document.querySelector(':root');
    if (rootElement instanceof HTMLElement) {
        rootElement.dataset[setting] = value;
    }
    for (const $iframe of document.getElementsByTagName('iframe')) {
        const iframeRoot = $iframe.contentWindow?.document?.querySelector(':root') as HTMLElement | null;
        if (iframeRoot) {
            iframeRoot.dataset[setting] = value;
        }

        for (const frame of iframeRoot?.getElementsByTagName('iframe') ?? []) {
            const innerRoot = frame.contentWindow?.document?.querySelector(':root') as HTMLElement | null;
            if (innerRoot) {
                innerRoot.dataset[setting] = value;
            }
        }
    }
}

function updateTheme(theme: string | null): void {
    updateUISetting('theme', theme === null ? 'light' : theme);
}
